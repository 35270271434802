import React from 'react';
import {
  Link,
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError } from
'@remix-run/react';
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';
import { Analytics } from '@vercel/analytics/react';
import { json } from '@vercel/remix';
import type { LinksFunction, MetaFunction } from '@vercel/remix';
import rdtStylesheet from 'remix-development-tools/index.css';
import { $path } from 'remix-routes';
import { ExternalScripts } from 'remix-utils/external-scripts';
import { useHydrated } from 'remix-utils/use-hydrated';

import { KotaSymbolAndWordmarkLogo } from '@yonderlabs-packages/brand-assets';
import { Spinner, Toaster } from '@yonderlabs-packages/ui';

import { env } from './config/env.server';
import { supportEmail } from './src/features/support';
import { useTrackPage } from './src/shared/integrations';
import { getSeo } from './src/shared/seo';
import tailwindStylesheetUrl from './styles/app.css';

declare global {
  interface Window {
    ENV: {
      SITE_ENV: string;
      DATADOG_APPLICATION_ID: string;
      DATADOG_CLIENT_TOKEN: string;
      DATADOG_ENV: string;
      DATADOG_SERVICE: string;
      DATADOG_SITE: string;
      NODE_ENV: string;
      SEGMENT_WRITE_KEY: string;
      SENTRY_DSN: string;
      API_BASE_URL: string;
    };
  }
}

export const loader = () =>
json({
  ENV: {
    DATADOG_APPLICATION_ID: env.DATADOG_APPLICATION_ID,
    DATADOG_CLIENT_TOKEN: env.DATADOG_CLIENT_TOKEN,
    DATADOG_ENV: env.DATADOG_ENV,
    DATADOG_SERVICE: env.DATADOG_SERVICE,
    DATADOG_SITE: env.DATADOG_SITE,
    NODE_ENV: env.NODE_ENV,
    SEGMENT_WRITE_KEY: env.SEGMENT_WRITE_KEY,
    SENTRY_DSN: env.SENTRY_DSN,
    API_BASE_URL: env.API_BASE_URL,
    SITE_ENV: env.SITE_ENV
  }
});

export const meta: MetaFunction = () => getSeo();

const devTools = process.env.NODE_ENV === 'development' ? [{ rel: 'stylesheet', href: rdtStylesheet }] : [];

export const links: LinksFunction = () => [
...devTools,
...FONTS.map((font) => ({ rel: 'preload', as: 'font', href: font, crossOrigin: 'anonymous' })),
{ rel: 'icon', type: 'image/x-icon', href: '/favicon.ico?v=1' },
{ rel: 'stylesheet', href: tailwindStylesheetUrl }];


function Document({ children }: {children: React.ReactNode;}) {
  const { ENV } = useLoaderData<typeof loader>();
  const isHydrated = useHydrated();

  return (
    <html lang='en'>
      <head>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <Meta />
        <Links />
      </head>

      <body className='min-h-screen antialiased'>
        {isHydrated ?
        children :

        <div className='flex h-screen items-center justify-center'>
            <Spinner className='h-6 w-6 animate-spin text-primary' />
          </div>}


        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(ENV)}`
          }} />


        <Toaster />
        <ScrollRestoration />
        <ExternalScripts />
        <Scripts />
        <LiveReload />
        <Analytics />
      </body>
    </html>);

}

function App() {
  useTrackPage();

  return (
    <Document>
      <Outlet />
    </Document>);

}

function ErrorBoundary() {
  const { ENV } = useLoaderData<typeof loader>();

  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  return (
    <html lang='en'>
      <head>
        <Meta />
        <Links />
      </head>

      <body className='min-h-screen antialiased'>
        <div className='flex min-h-full flex-col pb-12 pt-16'>
          <main className='mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8'>
            <div className='flex flex-shrink-0 justify-center'>
              <Link prefetch='intent' to={$path('/')} className='inline-flex'>
                <KotaSymbolAndWordmarkLogo className='h-28 w-auto' />
              </Link>
            </div>

            <div className='py-16'>
              <div className='text-center'>
                <p className='text-sm font-semibold uppercase tracking-wide text-yonder-600'>unhandled error</p>

                <h1 className='mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl'>
                  We are sorry, but something went wrong.
                </h1>

                <p className='mt-2 text-base text-gray-500'>
                  If this problem persists, please contact us at {supportEmail}
                </p>

                <div className='mt-6'>
                  <Link prefetch='intent' to={$path('/')} className='primary-link'>
                    Go back home
                    <span aria-hidden='true'> &rarr;</span>
                  </Link>
                </div>
              </div>
            </div>
          </main>
        </div>

        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(ENV)}`
          }} />


        <Scripts />
        <LiveReload />
      </body>
    </html>);

}

let AppExport = withSentry(App, {
  errorBoundaryOptions: {
    fallback: ErrorBoundary
  }
});

if (process.env.NODE_ENV === 'development') {
  const { withDevTools } = require('remix-development-tools');
  AppExport = withDevTools(AppExport);
}

export default AppExport;

const FONTS = [
'/fonts/Faktum.woff',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.eot',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.svg',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.ttf',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.woff',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.woff2',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-200.eot',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-200.svg',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-200.ttf',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-200.woff',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-200.woff2',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.eot',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.svg',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.ttf',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.woff',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.woff2',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-400.eot',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-400.svg',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-400.ttf',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-400.woff',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-400.woff2',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.eot',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.svg',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.ttf',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.woff',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.woff2',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.eot',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.svg',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.ttf',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.woff',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.woff2',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.eot',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.svg',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.ttf',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.woff',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.woff2',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-800.eot',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-800.svg',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-800.ttf',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-800.woff',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-800.woff2',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.eot',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.svg',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.ttf',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.woff',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.woff2',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.eot',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.svg',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.ttf',
'/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.woff'];